<template>
  <KTCard ref="preview" v-bind:title="title" v-bind:example="true">
    <template v-slot:title>
      {{ title }}
    </template>
    <template v-slot:body>
      <div>
        <div class="timeline timeline-justified timeline-4">
          <div class="timeline-bar"></div>
          <div
            class="timeline-items"
            v-for="item in items"
            v-bind:key="item.id"
          >
            <div class="timeline-item" v-bind:key="item.id">
              <div class="timeline-badge">
                <div class="bg-danger"></div>
              </div>
              <div class="timeline-label">
                <span class="text-primary font-weight-bold"><br /></span>
              </div>
              <div class="timeline-content">
                {{ item.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </KTCard>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_REBUTTAL_STATEMENT,
  SET_REBUTTAL_STATEMENT
} from "@/core/services/store/faq/rebuttal_statement.module";

export default {
  name: "RebuttalStatement",
  data() {
    return {
      title: this.$t("MENU.REBUTTAL_STATEMENT"),
      items: []
    };
  },
  components: {
    KTCard
  },
  computed: mapGetters(["getRebuttalStatement"]),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.FAQ"), route: "general-definition" },
      { title: this.$t("MENU.REBUTTAL_STATEMENT") }
    ]);
    this.getItems();
  },
  methods: {
    getItems() {
      this.$store.dispatch(GET_REBUTTAL_STATEMENT);
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === SET_REBUTTAL_STATEMENT) {
          this.items = this.getRebuttalStatement;
        }

        // if (mutation.type === SET_REBUTTAL_STATEMENT_LOADING) {
        //   this.isBusy = this.get;
        // }
      });
    }
  }
};
</script>

<style scoped></style>
